import { render, staticRenderFns } from "./transportApprove.vue?vue&type=template&id=aa0e2868&scoped=true&"
import script from "./transportApprove.vue?vue&type=script&lang=js&"
export * from "./transportApprove.vue?vue&type=script&lang=js&"
import style0 from "./transportApprove.vue?vue&type=style&index=0&id=aa0e2868&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa0e2868",
  null
  
)

export default component.exports