<!--
 * @Author: 许波
 * @Date: 2021-07-10 12:43:50
 * @LastEditTime: 2023-02-06 21:26:44
-->
<template>
  <div class="main module-wrap" v-if="$route.name === 'TransportCountDetail'">
    <div class="print-com">
      <el-form class="flex fsbc"
        :label-position="labelPosition"
        label-width="80px"
        :model="formData"
      >
        <div class="input-area">
          <el-form-item label="年份">
            <el-select class="w160" v-model="formData.year" placeholder="请选择年份">
              <el-option :label="year.label"
              :value="year.value" v-for="year in years"
              :key="year.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="月份">
            <el-select class="w160" v-model="formData.month" @change="getDays" placeholder="请选择月份">
              <el-option :label="month.label"
              :value="month.value" v-for="month in months"
              :key="month.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="日期">
            <el-select  class="w160" v-model="formData.day" placeholder="请选择日期">
              <el-option :label="day.label"
              :value="day.value" v-for="day in days"
              :key="day.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="运输状态">
            <el-select  class="w160" v-model="formData.complete" placeholder="请选择日期">
              <el-option :label="item.label" v-for="item in transportState"
              :key="item.val" :value="item.val"/>
            </el-select>
          </el-form-item>
          <el-form-item label="项目" v-if="isGroupUser">
            <el-select  class="w160" v-model="formData.projectUuid"
            @change="getSgfList" placeholder="请选择项目">
              <el-option :label="item.name" v-for="item in proList"
              :key="item.uuid" :value="item.uuid"/>
            </el-select>
          </el-form-item>
          <el-form-item label="施工方">
            <el-select  class="w160" v-model="formData.sgfUuid"
            @focus="checkSgfList" placeholder="请选择施工方">
              <el-option :label="item.name" v-for="item in sgfList"
              :key="item.uuid" :value="item.sgfUuid"/>
            </el-select>
          </el-form-item>
          <el-form-item label="弃土场">
            <el-select  class="w160" v-model="formData.qtcUuid" placeholder="请选择弃土场">
              <el-option :label="item.name" v-for="item in qtcList"
              :key="item.uuid" :value="item.uuid"/>
            </el-select>
          </el-form-item>
          <el-form-item label="车牌号">
            <el-input v-model="formData.carLicense" placeholder="请输入车牌号" class="w160"/>
          </el-form-item>
        </div>
        <div class="subitem-area">
          <p><el-button type="primary" @click="search">查询</el-button></p>
          <p><el-button type="primary" plain @click="resetData">重置</el-button></p>
        </div>
      </el-form>
      <div class="export-data">
        <el-button type="primary" plain v-if="isGroupUser" :disabled="tableData.length == 0"
        @click="exportDetailData">导出</el-button>
      </div>
      <div class="table-data">
        <el-table
          :data="tableData"
          border
          show-summary
          :summary-method="getTotalData"
          :header-cell-style="$rowClass"
          style="width: 100%"
        >
          <el-table-column prop="carLicense" width="160" label="车牌号" />
          <el-table-column prop="projectName" label="项目名称" />
          <el-table-column prop="sgfName" label="施工方" />
          <el-table-column prop="qtcName" label="弃土场" v-if="!isGroupUser" />
          <el-table-column prop="times" width="100" label="运输车次" />
          <el-table-column prop="capacity" width="100" label="运输方量" />

        </el-table>
        <div class="pagination-block">
          <el-pagination
            background
            :current-page.sync="page"
            @current-change="handlerCurPage"
            :total="count"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'; // 引入axios
import { mapGetters } from 'vuex';
import {
  // getToken,
  createYears,
  createMonths,
  createDays,
  globalClose,
  formatDate,
  exportData,
} from '../../utils/index';
import {
  $gp_groupTransStatistics,
  $gp_proTransStatistics,
  $gp_getQtcList,
  $gp_getProList,
  $gp_groupRelation,
  $gp_groupTransStatisticsTotal,
  // $gp_gropuTransExport,
} from '../../api/index';

export default {
  name: 'TransportCountDetail',
  data() {
    return {
      labelPosition: 'right',
      count: 5,
      page: 1,
      formData: {
        page: 0,
        size: 10,
      },
      tableData: [],
      sgfList: [],
      qtcList: [],
      proList: [],
      days: [],
      years: [],
      months: [],
      transportState: [
        { label: '全部', val: null },
        { label: '运输中', val: false },
        { label: '已完成', val: true },
      ],
      tableDataCount: [],
    };
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
    isGroupUser() {
      return this.userInfo.role === 'GROUP_MANAGER';
    },
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  created() {
    this.years = createYears(5);
    this.months = createMonths();
    this.getCurDate();
    this.getQtcList();
    this.getProList();
    this.groupTransList();
  },
  methods: {
    getCurDate() {
      const data = new Date();
      this.formData.year = data.getFullYear();
      this.formData.month = new Date().getMonth() + 1;
      this.getDays();
    },
    search() {
      console.log('search');
      const { carLicense, couponNo } = this.formData;
      if (!carLicense) delete this.formData.carLicense;
      if (!couponNo) delete this.formData.couponNo;
      this.groupTransList();
    },
    handlerCurPage(val) {
      this.formData.page = val - 1;
      this.groupTransList();
    },
    exportDetailData() {
      const url = '/api/group/trans/web/v1/export/statistics ';
      const params = this.formData;
      const fileName = '运输统计明细';
      exportData(url, params, fileName);
    },
    getQtcList() {
      $gp_getQtcList({ size: 100 }).then((res) => {
        console.log('gp_getQtcList', res);
        this.qtcList = res.list;
      });
    },
    getProList() {
      $gp_getProList({ size: 100 }).then((res) => {
        console.log('getProList', res);
        this.proList = res.list;
      });
    },
    resetData() {
      this.formData = {
        page: 0,
        size: 10,
      };
      this.getCurDate();
    },
    // 获取已经登记的施工方列表
    getSgfList(projectUuid) {
      const params = { size: 100, projectUuid };
      $gp_groupRelation(params).then((res) => {
        this.sgfList = res.list;
      });
    },
    checkSgfList() {
      console.log(this.formData);
      if (!this.formData.projectUuid) { this.$message.error('请先选择项目'); this.sgfList = []; }
      return false;
    },
    handleSizeChange(val) {
      console.log(val);
    },
    formatStartTime({ startTime }) {
      return formatDate(startTime);
    },
    formatEndTime({ endTime }) {
      return formatDate(endTime);
    },
    formatComplete({ complete }) {
      console.log(complete);
      return complete ? '完成' : '运输中';
    },
    getDays() {
      this.days = createDays(this.formData.month, this.formData.year);
    },
    groupTransList() {
      let func = $gp_proTransStatistics;
      let funcTotal = null;
      if (this.isGroupUser) {
        funcTotal = $gp_groupTransStatisticsTotal;
        func = $gp_groupTransStatistics;
      }
      func(this.formData).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
      if (funcTotal) {
        funcTotal(this.formData).then((res) => {
          console.log(res);
          this.tableDataCount = ['合计', '-', '-', res.totalTimes, res.totalCapacity];
          console.log(this.tableDataCount);
        });
      }
    },
    getTotalData(param) {
      console.log(param);
      const sums = this.tableDataCount;
      return sums;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-item {
  width: 48%;
}
.submit-area {
  padding: 20px;
  text-align: center;
}
.input-area{
  display: flex;
  flex-wrap: wrap;
}

// .w160{
//   width: 190px;
// }
.input-area{
  flex: 1
}
.subitem-area{
  display: flex;
  flex-direction: column;
  width: 100px;
  p{
    box-sizing: border-box;
    text-align: bottom;
    &:last-child{
      margin-top: 24px;
      margin-bottom: 20px
    }
  }
}
.export-data{
  padding-bottom: 10px;
}
</style>
