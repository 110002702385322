<!--
 * @Author: 许波
 * @Date: 2021-07-13 20:11:20
 * @LastEditTime: 2023-02-09 22:19:04
-->
<template>
  <div class="module-wrap" v-if="ishowCom">
     <el-form label-width="140px" :rules="rules" :model="formData">
       <div class="flex">
          <div class="flex-item">
            <el-form-item label="车牌号码" prop="carNo" required>
              <el-input v-model="formData.carNo" :disabled="isDisableEdit" class="w300">
                <el-button
                slot="append"
                icon="el-icon-search"
                @click="getCarInfo(formData.carNo)" />
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="车主姓名">
              <span>{{formData.ownerName}}</span>
            </el-form-item> -->
            <el-form-item label="修改前核载方量">
              <span>{{formData.capacity}}</span>
            </el-form-item>
          </div>
          <div class="flex-item">
            <el-form-item label="入库时间">
              <span>{{formData.joinTime}}</span>
            </el-form-item>
            <!-- <el-form-item label="联系方式">
              <span>{{formData.mobile}}</span>
            </el-form-item> -->
            <el-form-item label="修改后核载方量" prop="newCapacity" required>
              <el-input v-model="formData.newCapacity"
                :disabled="isDisableEdit"
                class="w300">
                <template slot="append">方</template>
              </el-input>
            </el-form-item>
          </div>
       </div>
        <el-form-item label="生效日期" prop="status">
          <el-date-picker
            :disabled="isDisableEdit"
            v-model="formData.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期" />
            <span class="pl10">
              从生效日期开始的运输记录按修改后的方量进行计算
            </span>
        </el-form-item>
        <div class="flex">
          <div class="flex-item">
            <el-form-item label="发起时间">
              <span>{{formData.createTime}}</span>
            </el-form-item>
          </div>
          <div class="flex-item">
            <el-form-item label="发起人员">
              <span>{{formData.createBy}}</span>
            </el-form-item>
          </div>
        </div>
        <el-form-item label="确认项目" prop="projectUuid" v-if="!isDisableEdit">
          <el-select class="w400" v-model="formData.projectUuid" placeholder="请选择一个该车辆服务过的项目进行核截确认">
              <el-option
                :label="item.projectName"
                :value="item.projectUuid"
                v-for="item in proList"
                :key="item.projectUuid"
              />
          </el-select>
        </el-form-item>
      </el-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  $gp_capacityCreate, $gp_getCarInfoByLicense, $gp_getProInfoByLicense, $gp_getCarDetail,
} from '../../api/index';
import { globalClose, formatDate } from '../../utils/utils';

const comNames = ['CarLoadEdit', 'CarLoadDetail'];

export default {
  name: 'CarLoadEdit',
  data() {
    return {
      formData: {
        carNo: '',
        carUuid: '',
        startDate: '',
        newCapacity: 0,
        projectUuid: '',
        proList: [],
      },
      // dialogVisible: false,
      rules: {
        carNo: [{ required: true, message: '请输入车牌号', trigger: 'blur' }],
        newCapacity: [{ required: true, message: '请输入修改后方量', trigger: 'blur' }],
        startDate: [{ required: true, message: '请选择生效日期', trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapGetters(['updateTime']),
    // 组件是否显示
    ishowCom() {
      const comName = this.$route.name;
      return comNames.includes(comName);
    },
    isDisableEdit() {
      const comName = this.$route.name;
      return comName === 'CarLoadDetail';
    },
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
        if (val.tag === 'onConfirm') { this.capacityEdit(); }
        if (val.tag === 'onCancel') { this.$router.back(); }
      }
    },
  },
  created() {
    const { applyUuid } = this.$route.query;
    if (applyUuid) {
      this.getCarDetail(applyUuid);
    }
  },

  methods: {
    validate() {
      const fields = Object.keys(this.rules);
      for (let i = 0; i < fields.length; i += 1) {
        if (!this.formData[fields[i]]) {
          this.$message.error(this.rules[fields[i]][0].message);
          return false;
        }
      }
      return true;
    },
    // 格式化时间
    formatDate(val) {
      return formatDate(val);
    },
    isShowApproveInfo(state) {
      const status = ['REFUSE', 'PASS'];
      return status.includes(state);
    },
    /**
     * @description: 修改方量
     */
    capacityEdit() {
      const checkResult = this.validate();
      if (!checkResult) return;
      console.log(this.formData.capacity, this.formData.newCapacity);
      if (+this.formData.capacity === +this.formData.newCapacity) {
        this.$message.error('修改后的方量不能和修改前的方量一致');
        return;
      }
      const params = {
        carUuid: this.formData.carUuid,
        projectUuid: this.formData.projectUuid,
        newCapacity: this.formData.newCapacity,
        startDate: this.formData.startDate,
      };
      $gp_capacityCreate(params).then(() => {
        this.$parent.groupCarList();
        this.$router.back();
      });
    },
    /**
     * @description: 获取车辆信息
     * @param {String} carNo 车牌号
     */
    getCarInfo(carNo) {
      $gp_getCarInfoByLicense({ carNo }).then((res) => {
        this.formData = {
          ...this.formData,
          ...res,
        };
        this.getProInfoByLicense(res.carUuid);
      });
    },
    getProInfoByLicense(carUuid) {
      $gp_getProInfoByLicense({ carUuid }).then((res) => {
        this.proList = res;
      });
    },
    getCarDetail(applyUuid) {
      $gp_getCarDetail({ applyUuid }).then((res) => {
        this.formData = res;
        this.formData.capacity = res.oldCapacity;
      });
    },

  },
};
</script>

<style lang="scss" scoped>
  .t-gd-custom-map {
    height: 300px !important;
    width: 100%;
    .amap-marker-label {
      color: #ff4739 !important;
      border: none !important;
      background-color: transparent !important;
      font-size: 0.8em !important;
    }
    .t-amap-info-window {
      background-color: white;

      p {
        margin: 0;
        color: #adadad;
        span:last-child {
          font-size: 0.8em;
        }
      }
    }
  }
</style>
