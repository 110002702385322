<!--
 * @Author: 许波
 * @Date: 2021-07-11 17:44:13
 * @LastEditTime: 2023-01-31 00:58:19
 * @LastEditors: Please set LastEditors
 * @FilePath: \data-admin\src\views\ticket\ticketCoutn.vue
-->
<template>
  <div>
    <div class="prant-com module-wrap" v-if="$route.name === 'GateRecordManual'">
      <el-form :inline="true" label-width="80px" :model="formData">
        <el-row>
            <!-- <el-form-item label="年份">
              <el-select class="w140" v-model="formData.year" placeholder="请选择年份">
                <el-option
                  :label="year.label"
                  :value="year.value"
                  v-for="year in years"
                  :key="year.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="月份">
              <el-select
                class="w140"
                v-model="formData.month"
                placeholder="请选择月份"
                @change="getDays"
              >
                <el-option
                  :label="month.label"
                  :value="month.value"
                  v-for="month in months"
                  :key="month.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="日期">
              <el-select class="w140" v-model="formData.day" placeholder="请选择日期">
                <el-option
                  v-for="day in days"
                  :label="day.label"
                  :value="day.value"
                  :key="day.value" />
              </el-select>
            </el-form-item> -->
            <el-form-item label="日期">
              <el-date-picker
                v-model="formData.date"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期" />
            </el-form-item>
            <el-form-item label="弃土场">
              <QtcSelect :width="250" v-model="formData.qtcUuid"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search">查询</el-button>
              <el-button type="primary" plain @click="resetFormData">重置</el-button>
            </el-form-item>
        </el-row>
      </el-form>
      <div class="table-area">
        <el-table
          :data="tableData"
          border
          :header-cell-style="$rowClass"
          style="width: 100%"
        >
          <el-table-column prop="qtcName" label="弃土场名称" />
          <el-table-column prop="channelId" label="闸机id" />
          <el-table-column prop="channelName" label="闸机名称" />
          <el-table-column prop="time" label="通过时间" />
          <el-table-column prop="operator" label="操作人" />
          <el-table-column prop="remark" label="理由" />
        </el-table>
        <div class="pagination-block">
          <el-pagination
            background
            :current-page.sync="formData.page"
            @current-change="handlerCurPage"
            :total="count"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <div class="child-com">
      <router-view></router-view>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  formatDate, createDays, globalClose,
} from '../../utils/index';
import { $gp_gateRecordManual } from '../../api/index';
import QtcSelect from '../../components/QtcSelect.vue';

export default {
  name: 'GateRecordManual',
  components: {
    QtcSelect,
  },
  data() {
    return {
      count: 4,
      tableData: [],
      formData: {
        size: 10,
        page: 1,
        date: '',
      },
      years: [],
      months: [],
      days: [],
    };
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  mounted() {
    this.formData.date = formatDate(new Date(), 'yyyy-MM-dd');
    this.gateRecordManual();
  },
  methods: {

    handlerCurPage(val) {
      this.formData.page = val - 1;
      this.gateRecordManual();
    },
    search() {
      this.gateRecordManual();
    },
    refreshData() {
      this.gateRecordManual();
    },
    getDays() {
      this.days = createDays(this.formData.month, this.formData.year);
    },
    resetFormData() {
      this.formData = {
        size: 10,
        page: 1,
        date: formatDate(new Date(), 'yyyy-MM-dd'),
      };
    },
    // formaTime({ date }) {
    //   return formatDate(date, 'yyyy-MM-dd HH:mm');
    // },
    gateRecordManual() {
      if (this.formData.status === 1) {
        delete this.formData.status;
      }
      console.log(this.formData);
      $gp_gateRecordManual(this.formData).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.w500 {
  width: 500px;
}
.table-area {
  position: relative;
  .count-detail {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 10;
  }
}
</style>
