<template>
  <div class="main module-wrap" v-if="isShowCom">
    <el-form class="w-80" :label-position="labelPosition" label-width="150px"
      :model="formData">
      <div class="head-input flex">
        <div class="input-item">
          <el-form-item label="运输日期：" required>
            <el-date-picker
              v-model="formData.date"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期" />
          </el-form-item>
          <el-form-item label="客户单位：">
            <span>{{formData.customer}}</span>
          </el-form-item>
          <el-form-item label="运输车次：">
            <el-input type="number" class="w220" v-model="formData.times"
            @change="countCapacity"
            />
          </el-form-item>
        </div>
        <div class="input-item">
          <el-form-item label="出土方：" required>
            <div class="flex">
              <el-select class="w140" v-model="outUuidSpaceType"
              @change="(val)=>{handlerSpace(1, val)}" placeholder="请选择">
                <el-option
                  v-for="qtc in spaceTypes"
                  :label="qtc.label"
                  :value="qtc.value"
                  :key="qtc.value" />
              </el-select>
              <el-select class="w240"
              v-model="formData.outUuid"
              @change="handlerCustomer"
              placeholder="请选择：">
                <el-option
                  v-for="qtc in outSpaceList"
                  :label="qtc.spaceName"
                  :value="qtc.spaceUuid"
                  :key="qtc.spaceUuid" />
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="进土方：" required>
            <div class="flex">
              <el-select class="w140" v-model="inUuidSpaceType"
              @change="(val)=>{handlerSpace(2, val)}" placeholder="请选择">
                <el-option
                  v-for="qtc in spaceTypes"
                  :label="qtc.label"
                  :value="qtc.value"
                  :key="qtc.value" />
              </el-select>
              <el-select class="w240" v-model="formData.inUuid" placeholder="请选择">
                <el-option
                  v-for="qtc in inSpaceList"
                  :label="qtc.spaceName"
                  :value="qtc.spaceUuid"
                  :key="qtc.spaceUuid" />
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="运输方量：">
            <el-input type="number" class="w220" v-model="formData.capacity"
            />
          </el-form-item>
        </div>
      </div>
      <div>
        <el-form-item label="备注：">
          <el-input
          v-model="formData.remark"
          maxlength="200"
          show-word-limit
          type="textarea"
          :rows="3" />
        </el-form-item>
      </div>
      <div style="padding-left:84px">
          <UploadFile :list="formData.fileList" @onChange="onChange"/>
        </div>
      <div class="head-input flex">
        <div class="input-item">
          <el-form-item label="操作人员：">
            <span>{{formData.operatorName}}</span>
          </el-form-item>
        </div>
        <div class="input-item">
           <el-form-item label="操作时间：">
            <span>{{formatDate(formData.createTime)}}</span>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  $gp_groupTransCreate,
  $gp_getSpace,
} from '../../api/index';
import {
  globalClose, deepClone, formatDate,
} from '../../utils/utils';
import UploadFile from '../../components/UploadFile.vue';

export default {
  name: 'TransportCreate',
  components: { UploadFile },
  data() {
    return {
      labelPosition: 'right',
      isShowEdit: false,
      formData: {
        date: '',
        inUuid: '',
        outUuid: '',
        times: 0,
        capacity: 0,
        remark: '',
        fileList: [],
        customer: '',
      },
      outUuidSpaceType: 1,
      inUuidSpaceType: 2,
      uuid: '',
      loading: false,
      clintName: '',
      spaceTypes: [
        { value: 1, label: '项目' },
        { value: 2, label: '弃土场' },
      ],
      outSpaceList: [],
      inSpaceList: [],
    };
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
    isShowCom() {
      const coms = ['TransportCreate', 'TransportEdit'];
      return coms.includes(this.$route.name);
    },
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'close') { globalClose(this); }
        if (val.tag === 'onConfirm') { this.submit(); }
      }
    },
  },
  methods: {
    /**
     * @description: 获取选中项目信息
     * @param {*} val
     * @return {*}
     */
    hadnlerProInfo(val) {
      console.log(val);
      this.clintName = val.customerOrg;
    },
    formatDate(time) {
      return formatDate(time);
    },
    countCapacity(val) {
      console.log(val, 'countCapacity----');
      this.formData.capacity = +val * 24;
    },
    handlerSpace(useType, spaceType) {
      console.log('useType', useType);
      console.log('spaceType', spaceType);
      this.getSpace(useType, spaceType);
    },
    getSpace(useType, spaceType) {
      $gp_getSpace({ spaceType, useType }).then((res) => {
        if (useType === 1) {
          this.outSpaceList = res;
          this.formData.outUuid = '';
        } else {
          this.inSpaceList = res;
          this.formData.inUuid = '';
        }
      });
    },
    handlerCustomer(val) {
      const info = this.outSpaceList.find((item) => item.spaceUuid === val);
      if (info) {
        this.formData.customer = info.customer;
      }
    },
    onChange(val) {
      this.formData.fileList = val;
    },
    submit() {
      const params = deepClone(this.formData);
      console.log(params);
      if (!params.date) { this.$message.error('运输日期不能为空！'); return; }
      if (!params.outUuid) { this.$message.error('请选择出土方！'); return; }
      if (!params.inUuid) { this.$message.error('请选择进土方！'); return; }
      // if (!params.times) { this.$message.error('请输入运输车次！'); return; }
      // if (!params.capacity) { this.$message.error('请输入运输方量！'); return; }
      $gp_groupTransCreate(params).then(() => {
        this.$message.success('操作成功');
        this.$parent.groupTransList();
        setTimeout(() => {
          this.$router.back();
        }, 1000);
      });
    },
    /**
     * @description: 刷新数据
     * @return {*}
     */
    // refreshData() {

    // },

  },
  created() {
    this.formData.date = formatDate(new Date(), 'yyyy-MM-dd');
    // 出土项目默认值
    this.getSpace(1, 1);
    // 进土项目默认值
    this.getSpace(2, 2);
  },
};
</script>

<style lang="scss" scoped>
  .input-item{
    width: 49%;
  }
  .submit-area{
    padding: 20px;
    text-align: center;
  }
  .w220{
    width: 220px;
  }
  .w-80{
    width: 70%;;
  }
  </style>
