<template>
  <div class="main module-wrap">
    <el-form class="w-80" :label-position="labelPosition" label-width="150px" :model="formData">
      <div class="head-input flex">
        <div class="input-item">
          <el-form-item label="运输日期：" required>
            {{ formData.transTime }}
          </el-form-item>
          <el-form-item label="客户单位：">
            <span>{{ formData.customer }}</span>
          </el-form-item>
          <el-form-item label="运输车次：">
            <span>{{ formData.times }}</span>
          </el-form-item>
        </div>
        <div class="input-item">
          <el-form-item label="出土方：" required>
            <span>{{ formData.outName }}</span>
          </el-form-item>
          <el-form-item label="进土方：" required>
            <span>{{ formData.inName }}</span>
          </el-form-item>
          <el-form-item label="运输方量：">
            <span>{{ formData.capacity }}</span>
          </el-form-item>
        </div>
      </div>
      <div>
        <el-form-item label="备注：">
          <span>{{ formData.remark }}</span>
        </el-form-item>
      </div>
      <UploadFile :scene="2" :list="formData.fileList" style="margin-left:40px" />
      <div class="head-input flex">
        <div class="input-item">
          <el-form-item label="操作时间：">
            <span>{{ formData.createTime }}</span>
          </el-form-item>
          <el-form-item label="预审时间：" v-if="isApprove">
            <span>{{ formData.preApproveTime }}</span>
          </el-form-item>
        </div>
        <!-- {{ formData }} -->
        <div class="input-item">
          <el-form-item label="操作人员：">
            <span>{{ formData.createBy }}</span>
          </el-form-item>
          <el-form-item label="预审人员：" v-if="isApprove">
            <span>{{ formData.preApproveBy }}</span>
          </el-form-item>
        </div>
      </div>
      <!-- <el-form-item label="预审结果:">
        <span>{{ formatPreState(formData.preApplyStatus) }}</span>
        <el-select v-model="formData.preApplyStatus" v-else>
          <el-option
            v-for="state in preApplyStatus"
            :value="state.value"
            :label="state.label"
            :key="state.value"
          />
        </el-select>
      </el-form-item> -->
      <el-form-item label="预审结果:" v-if="isApprove">
        <span v-if="iSOnlyBack">{{ formatState(formData.preApplyStatus) }}</span>
        <el-select v-model="formData.applyPass" v-else>
          <el-option
            v-for="state in resultState"
            :value="state.value"
            :label="state.label"
            :key="state.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="预审意见：" v-if="isApprove">
        <span v-if="iSOnlyBack">{{ formData.preApplyComment }}</span>
        <el-input
          v-else
          v-model="formData.applyComment"
          placeholder="请输入预审备注（未通过时必填）"
          maxlength="50"
          show-word-limit
          type="text"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { $gp_transPreApprove, $gp_getTransApplyDetail } from "../../api/index";
import { globalClose } from "../../utils/utils";
import UploadFile from "../../components/UploadFile.vue";

export default {
  name: "PreApprove",
  components: { UploadFile },
  data() {
    return {
      labelPosition: "right",
      isShowEdit: false,
      formData: {
        date: "",
        inUuid: "",
        outUuid: "",
        times: 0,
        capacity: 0,
        remark: "",
        fileList: [],
        customer: ""
      },
      resultState: [
        { value: true, label: "已通过" },
        { value: false, label: "未通过" }
      ],
      preApplyStatus: [
        { value: 1, label: "待审批" },
        { value: 2, label: "已通过" },
        { value: 3, label: "未通过" },
        { value: 4, label: "已撤销" }
      ]
    };
  },
  computed: {
    ...mapGetters(["updateTime", "userInfo"]),
    isApprove() {
      return this.$route.name === "PreApprove";
    },
    iSOnlyBack() {
      return Boolean(this.$route.query.onlyBack);
    }
  },
  watch: {
    updateTime(val) {
      if (val.tag === "close") {
        globalClose(this);
      }
      if (val.tag === "onConfirm") {
        this.submit();
      }
      if (val.tag === "onCancel") {
        this.goBack();
      }
    }
  },
  methods: {
    handlerSpace(useType, spaceType) {
      console.log("useType", useType);
      console.log("spaceType", spaceType);
      this.getTransDetail(useType, spaceType);
    },
    getTransDetail() {
      $gp_getTransApplyDetail({ transUuid: this.transUuid }).then(res => {
        this.formData = res;
        if (res.status === 2) {
          this.formData.applyPass = true;
        } else if (res.status === 3) {
          this.formData.applyPass = false;
        }
      });
    },
    formatState(state) {
      console.log("state==", state);
      const status = {
        1: "待审批",
        2: "已通过",
        3: "已拒绝"
      };
      return status[state];
    },
    formatPreState(state) {
      console.log("formatPreState==", state);
      const status = {
        1: "待审批",
        2: "已通过",
        3: "未通过",
        4:"已撤销"
      };
      return status[state];
    },
    submit() {
      console.log("sub====");
      const { applyPass, applyComment, applyUuid } = this.formData;
      if (!applyPass && !applyComment) {
        this.$message.error("请填写审批意见");
        return;
      }
      $gp_transPreApprove({ transUuid: applyUuid, applyPass, applyComment }).then(() => {
        this.$message.success("操作成功");
        this.$parent.groupTransList();
        setTimeout(() => {
          this.$router.back();
        }, 1000);
      });
    },
    goBack() {
      this.$router.back();
    }
  },
  created() {
    this.transUuid = this.$route.query.transUuid;
    this.getTransDetail();
  }
};
</script>

<style lang="scss" scoped>
.input-item {
  width: 49%;
}
.submit-area {
  padding: 20px;
  text-align: center;
}
.w220 {
  width: 220px;
}
.w-80 {
  width: 70%;
}
</style>
