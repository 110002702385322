<!--
 * @Author: 许波
 * @Date: 2021-07-11 17:44:13
 * @LastEditTime: 2023-02-03 22:16:48
 * @LastEditors: Please set LastEditors
 * @FilePath: \data-admin\src\views\ticket\ticketCoutn.vue
-->
<template>
  <div>
    <div class="prant-com module-wrap" v-if="$route.name === 'GateRecordOut'">
      <el-form :inline="true" label-width="80px" :model="formData">
        <el-row>
            <el-form-item label="日期">
              <el-date-picker
                v-model="formData.date"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期" />
            </el-form-item>
            <el-form-item label="弃土场">
              <QtcSelect :width="250" v-model="formData.qtcUuid"/>
            </el-form-item>
            <el-form-item label="车牌类型">
              <el-select
                v-model="formData.carLicenseType"
                placeholder="请选择"
                >
                <el-option
                  v-for="item in carNoType"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="车辆类型">
              <el-select
                v-model="formData.carType"
                placeholder="请选择"
                >
                <el-option
                  v-for="item in carType"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="车牌">
              <el-input v-model="formData.carNo"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search">查询</el-button>
              <el-button type="primary" plain @click="resetFormData">重置</el-button>
            </el-form-item>
        </el-row>
      </el-form>
      <div class="table-area">
        <el-table
          :data="tableData"
          border
          :header-cell-style="$rowClass"
          style="width: 100%"
        >
          <el-table-column prop="qtcName" label="弃土场名称" />
          <el-table-column prop="carNo" label="车牌号码" />
          <el-table-column :formatter="formatCarNoType" label="车牌类型" />
          <el-table-column :formatter="formatCarType" label="车辆类型" />
          <el-table-column prop="inChannelId" label="入场通道ID" />
          <el-table-column prop="inChannelName" label="入场通道名称" />
          <el-table-column prop="inTime" label="入场时间" />
          <el-table-column prop="outChannelId" label="出场通道ID" />
          <el-table-column prop="outChannelName" label="出场通道名称" />
          <el-table-column prop="outTime" label="入场时间" />
        </el-table>
        <div class="pagination-block">
          <el-pagination
            background
            :current-page.sync="formData.page"
            @current-change="handlerCurPage"
            :total="count"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="child-com">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  createDays, formatDate, globalClose,
} from '../../utils/index';
import { $gp_gateRecordOut } from '../../api/index';
import QtcSelect from '../../components/QtcSelect.vue';
import { carNoType, carType } from '../../config';

export default {
  name: 'GateRecordOut',
  components: {
    QtcSelect,
  },
  data() {
    return {
      count: 4,
      tableData: [],
      formData: {
        size: 10,
        page: 1,
        date: '',
      },
      carNoType,
      carType,
    };
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  mounted() {
    this.formData.date = formatDate(new Date(), 'yyyy-MM-dd');
    this.gateRecordManual();
  },
  methods: {
    formatCarNoType({cardType} ) {
      const carNoInfo = this.carNoType.find((item) => item.value === cardType);
      return carNoInfo.label;
    },
    formatCarType({carType} ) {
      const carNoInfo = this.carType.find((item) => item.value === carType);
      return carNoInfo.label;
    },
    handlerCurPage(val) {
      this.formData.page = val;
      this.gateRecordManual();
    },
    search() {
      this.gateRecordManual();
    },
    refreshData() {
      this.gateRecordManual();
    },
    getDays() {
      this.days = createDays(this.formData.month, this.formData.year);
    },
    resetFormData() {
      this.formData = {
        size: 10,
        page: 0,
        date: formatDate(new Date(), 'yyyy-MM-dd'),
      };
    },
    formaTime({ date }) {
      return formatDate(date, 'yyyy-MM-dd HH:mm');
    },
    gateRecordManual() {
      if (this.formData.status === 1) {
        delete this.formData.status;
      }
      $gp_gateRecordOut(this.formData).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.w500 {
  width: 500px;
}
.table-area {
  position: relative;
  .count-detail {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 10;
  }
}
</style>
