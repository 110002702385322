<!--
 * @Author: 许波
 * @Date: 2021-07-11 17:44:13
 * @LastEditTime: 2023-02-06 21:37:16
 * @LastEditors: Please set LastEditors
 * @FilePath: \data-admin\src\views\ticket\ticketCoutn.vue
-->
<template>
  <div>
    <div class="prant-com module-wrap" v-if="$route.name === 'FreeVehicleList'">
      <el-form :inline="true" label-width="80px" :model="formData">
        <el-row>
            <el-form-item label="弃土场">
              <QtcSelect :width="250" v-model="formData.qtcUuid" />
            </el-form-item>
            <el-form-item label="车牌号">
              <el-input v-model="formData.carNo" placeholder="请输入车牌号" />
            </el-form-item>
            <el-form-item label="到期日期">
              <el-date-picker
                v-model="formData.endDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search">查询</el-button>
              <el-button type="primary" plain @click="resetFormData">重置</el-button>
            </el-form-item>
        </el-row>
      </el-form>
      <div class="pb10">
        <el-button type="primary" @click="createFreeCar">新增</el-button>
        <el-button @click="exportRecordlData">导出</el-button>
      </div>
      <div class="table-area">
        <el-table
          :data="tableData"
          border
          :header-cell-style="$rowClass"
          style="width: 100%"
        >
          <el-table-column prop="qtcName" label="弃土场名称" />
          <el-table-column prop="carNo" label="车牌号码" />
          <el-table-column prop="carOwner" label="车主姓名" />
          <el-table-column :formatter="formatCarNoType" label="车牌类型" />
          <el-table-column :formatter="formatCarType" label="车辆类型" />
          <el-table-column prop="startDate" label="生效日期" />
          <el-table-column prop="endDate" label="到期日期" />
          <el-table-column prop="remark" label="备注" />
          <el-table-column prop="createDate" label="创建日期" />
          <el-table-column prop="createBy" label="创建者" />
          <el-table-column prop="refreshDate" label="修改日期" />
          <el-table-column prop="refreshBy" label="修改者" />
          <el-table-column  label="操作">
            <template slot-scope="scope">
              <span class="theme-color pointer pr10"
                @click="editFreeCar(scope.row)">修改</span>
              <span class="theme-color pointer" @click="delWhiteCar(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-block">
          <el-pagination
            background
            :current-page.sync="formData.page"
            @current-change="handlerCurPage"
            :total="count"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="child-com">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  createYears, createMonths, createDays, formatDate, globalClose, exportData,
} from '../../utils/index';
import { $gp_groupWhiteList, $gp_groupDelWhiteCar } from '../../api/index';
import QtcSelect from '../../components/QtcSelect.vue';
// import CardSelect from '../../components/CardSelect.vue';

export default {
  name: 'FreeVehicleList',
  components: { QtcSelect },
  data() {
    return {
      count: 0,
      tableData: [],
      formData: {
        size: 10,
        page: 1,
      },
      years: [],
      months: [],
      days: [],
    };
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  mounted() {
    this.years = createYears(5);
    this.months = createMonths();
    // this.getQtcList();
    this.gateRecordManual();
  },
  methods: {

    handlerCurPage(val) {
      this.formData.page = val;
      this.gateRecordManual();
    },
    search() {
      this.gateRecordManual();
    },
    refreshData() {
      this.gateRecordManual();
    },
    getDays() {
      this.days = createDays(this.formData.month, this.formData.year);
    },
    resetFormData() {
      this.formData = {
        size: 10,
        page: 1,
      };
    },
    formatStartTime({ startTime }) {
      return formatDate(startTime, 'yyyy-MM-dd HH:mm');
    },
    formatExpireTime({ endDate }) {
      return formatDate(endDate, 'yyyy-MM-dd HH:mm');
    },
    gateRecordManual() {
      if (this.formData.status === 1) {
        delete this.formData.status;
      }
      console.log(this.formData);
      $gp_groupWhiteList(this.formData).then((res) => {
        console.log(res);
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
    // 新增白名单
    createFreeCar() {
      this.$router.push({ name: 'AddFreeCar' });
    },
    // 修改白名单
    editFreeCar(carInfo) {
      this.$router.push({ name: 'AddFreeCar', params: { carInfo } });
    },
    delWhiteCar(params) {
      this.$confirm('删除后10分钟内生效，你确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        // eslint-disable-next-line no-param-reassign
        delete params.carType;
        // eslint-disable-next-line no-param-reassign
        delete params.cardType;
        $gp_groupDelWhiteCar(params).then(() => {
          this.gateRecordManual();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作',
        });
      });
    },
    exportRecordlData() {
      const url = '/gate/v3/white/export';
      const params = this.formData;
      const fileName = '免费车辆';
      exportData(url, params, fileName, false);
    },
  },
};
</script>

<style lang="scss" scoped>
.w500 {
  width: 500px;
}
.pr10{
  padding-right: 10px;
}
.table-area {
  position: relative;
  .count-detail {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 10;
  }
}
</style>
