<!--
 * @Author: 许波
 * @Date: 2021-07-10 12:43:50
 * @LastEditTime: 2023-02-28 20:08:19
-->
<template>
  <div class="main">
    <div class="print-com module-wrap">
      <el-form class="flex fsbt"
        :label-position="labelPosition"
        label-width="80px"
        :model="formData"
      >
        <div class="input-area">
          <el-form-item label="日期">
            <el-date-picker
              :clearable="false"
              class="w160"
              v-model="formData.date"
              type="month"
              value-format="yyyy-MM"
              placeholder="选择日期"
            />
          </el-form-item>
          <el-form-item label="运输状态">
            <el-select  class="w160" v-model="formData.cancelType" placeholder="请选择日期">
              <el-option :label="item.label" v-for="item in cancelTypes"
              :key="item.val" :value="item.val"/>
            </el-select>
          </el-form-item>
          <el-form-item label="车牌号">
            <el-input v-model="formData.carNo" placeholder="请输入车牌号" class="w160"/>
          </el-form-item>
          <el-form-item label="出土方">
            <el-input v-model="formData.out" placeholder="请输入" class="w160"/>
          </el-form-item>
        </div>
        <div class="subitem-area">
          <el-button type="primary" @click="search">查询</el-button>
          <el-button type="primary" plain @click="resetData">重置</el-button>
          <p></p>
          <p></p>
        </div>
      </el-form>
      <div class="table-data">
        <el-table
          :data="tableData"
          border
          :header-cell-style="$rowClass"
          style="width: 100%"
        >
          <el-table-column prop="cancelTime"
            width="130"
            label="取消运输时间" />
          <el-table-column prop="outName" label="出土方" />
          <el-table-column prop="customer" label="客户单位" />
          <el-table-column prop="startTime" label="出发时间" />
          <el-table-column prop="carNo" width="160" label="车牌号" />
          <el-table-column :formatter="formatCancelType" width="120" label="取消方式" />
        </el-table>
        <div class="pagination-block">
          <el-pagination
            background
            :current-page.sync="formData.page"
            @current-change="handlerCurPage"
            :total="count"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  createDays,
  globalClose,
  formatDate,
} from '../../utils/index';
import { $gp_proTransCancelList } from '../../api/index';

export default {
  name: 'TransportCancel',
  data() {
    return {
      labelPosition: 'right',
      count: 0,
      formData: {
        page: 1,
        size: 10,
        cancelType: null,
        date: '',
        out: null,
        carNo: null,
      },
      tableData: [],
      cancelTypes: [
        { label: '全部', val: null },
        { label: '系统取消', val: 1 },
        { label: '手动取消', val: 2 },
      ],
    };
  },
  mounted() {
    this.resetData();
    this.groupTransCancelList();
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
    isGroupUser() {
      return this.userInfo.role === 'GROUP_MANAGER';
    },
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  activated() {
  },
  methods: {
    getCurMonth() {
      const data = new Date();
      const year = data.getFullYear();
      let month = new Date().getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      return `${year}-${month}`;
    },
    search() {
      console.log('search');
      const { carLicense, couponNo } = this.formData;
      if (!carLicense) delete this.formData.carLicense;
      if (!couponNo) delete this.formData.couponNo;
      this.formData.page = 1;
      this.groupTransCancelList();
    },

    handlerCurPage(val) {
      this.formData.page = val;
      this.groupTransCancelList();
    },
    resetData() {
      this.formData = {
        page: 1,
        size: 10,
        cancelType: null,
        out: null,
        carNo: null,
        date: this.getCurMonth(),
      };
    },
    formatCancelTime({ cancelTime }) {
      return formatDate(cancelTime);
    },
    formatCancelType({ cancelType }) {
      console.log(cancelType);
      return cancelType === 1 ? '系统取消' : '手动取消';
    },
    getDays() {
      this.days = createDays(this.formData.month, this.formData.year);
    },
    groupTransCancelList() {
      const {
        page, size, cancelType, out, carNo, date,
      } = this.formData;
      const params = {
        page,
        size,
        cancelType,
        out,
        carNo,
        year: date.split('-')[0],
        month: this.formData.date.split('-')[1],
      };
      $gp_proTransCancelList(params).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-item {
  width: 48%;
}
.submit-area {
  padding: 20px;
  text-align: center;
}
.input-area{
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.input-area{
  flex: 1
}

</style>
