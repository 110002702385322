<!--
 * @Author: 许波
 * @Date: 2021-07-11 17:44:13
 * @LastEditTime: 2023-02-11 20:26:02
 * @LastEditors: Please set LastEditors
 * @FilePath: \data-admin\src\views\ticket\ticketCoutn.vue
-->
<template>
  <div>
    <div class="prant-com module-wrap" v-if="$route.name === 'CarLoadList'">
      <el-form :inline="true" label-width="90px" :model="formData">
        <el-form-item label="状态">
          <el-select class="w160" v-model="formData.applyStatus">
              <el-option
                :label="item.label"
                :value="item.value"
                v-for="item in status"
                :key="item.value"
              />
          </el-select>
        </el-form-item>
        <el-form-item label="车牌号码">
          <el-input class="w350" v-model="formData.license" placeholder="请输入车牌号" />
        </el-form-item>
        <el-form-item class="center">
          <el-button type="primary" @click="search">查询</el-button>
          <el-button type="primary" plain @click="resetFormData">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="toEditPage">发起修改</el-button>
      <div class="table-area mt10" >
        <el-table
          :data="tableData"
          border
          :header-cell-style="$rowClass"
          style="width: 100%"
        >
          <el-table-column  prop="createTime" width="160" label="发起时间" />
          <el-table-column prop="carNo" label="车牌号码" />
          <el-table-column prop="oldCapacity" align="center" width="150" label="修改前核载方量(方)" />
          <el-table-column prop="newCapacity" align="center" width="150" label="修改后核载方量(方)" />
          <el-table-column prop="startDate" align="center" label="生效日期" />
          <el-table-column prop="createBy" align="center" label="发起人员" />
          <el-table-column prop="projectName" align="center" show-overflow-tooltip label="确认项目" />
          <el-table-column :formatter="formatState" align="center" width="110" label="状态" />
          <el-table-column label="操作"  align="center" width="120">
            <template slot-scope="scope">
              <!-- <span v-if="scope.row.applyStatus === 1"
                class="theme-color pointer"
                @click="capacityCancel(scope.row)">
                撤销
              </span> -->
              <span
                class="theme-color pointer pl10"
                @click="gotoDetail(scope.row)">
                详情
              </span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-block">
          <el-pagination
            background
            :current-page.sync="formData.page"
            @current-change="handlerCurPage"
            :total="count"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="child-com">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  formatDate, globalClose,
} from '../../utils/index';
import { $gp_capacityPage, $gp_capacityCancel } from '../../api/index';

export default {
  name: 'CarLoadEdit', // 核载修改
  data() {
    return {
      count: 4,
      page: 1,
      tableData: [],
      qtcList: [],
      formData: {
        size: 10,
        page: 1,
        applyStatus: null,
      },
      years: [],
      months: [],
      days: [],
      dialogVisible: false,
      drivingLicenseUrl: '',
      status: [
        { label: '全部', value: null },
        { label: '待确认', value: 1 },
        { label: '已确认', value: 2 },
        { label: '已撤消', value: 3 },
        { label: '已拒绝', value: 4 },
      ],
    };
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
    isProIndex() {
      return this.userInfo.role === 'PROJECT_MANAGER';
    },
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
    // $route() {
    //   // 监听路由是否变化
    //   this.groupCarList();
    // },

  },
  mounted() {
    this.groupCarList();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handlerCurPage(val) {
      console.log(val);
      this.formData.page = val;
      this.groupCarList();
    },
    search() {
      this.groupCarList();
    },
    refreshData() {
      this.groupCarList();
    },
    resetFormData() {
      this.formData = {
        size: 10,
        page: 1,
      };
    },
    toEditPage() {
      this.$router.push('/carLoadList/carLoadEdit');
    },
    gotoDetail({ applyUuid }) {
      this.$router.push({ path: '/carLoadList/carLoadDetail', query: { applyUuid } });
    },
    formaTime({ operatorDate }) {
      return formatDate(operatorDate, 'yyyy-MM-dd HH:mm:ss');
    },
    formatEffectiveDate({ effectiveDate }) {
      return formatDate(effectiveDate, 'yyyy-MM-dd HH:mm:ss');
    },
    formatState({ applyStatus }) {
      const state = {
        1: '待确认',
        2: '已确认',
        3: '已撤消',
        4: '已拒绝',
      };
      return state[applyStatus];
    },
    seeDrivingLicense({ drivingLicenseUrl }) {
      this.dialogVisible = true;
      this.drivingLicenseUrl = drivingLicenseUrl;
    },
    groupCarList() {
      $gp_capacityPage(this.formData).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },
    // 撤销方量修改请求
    capacityCancel({ applyUuid, carNo }) {
      const msg = `你确定要撤销${carNo}的核载方量修改吗？`;
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          $gp_capacityCancel({ applyUuid }).then((res) => {
            console.log('res', res);
            this.groupCarList();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.w500 {
  width: 500px;
}
.table-area {
  position: relative;
  .count-detail {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 10;
  }
}
</style>
