<!--
 * @Author: 许波
 * @Date: 2021-07-11 17:44:13
 * @LastEditTime: 2023-02-03 00:31:58
 * @LastEditors: Please set LastEditors
 * @FilePath: \data-admin\src\views\ticket\ticketCoutn.vue
-->
<template>
  <div>
    <div class="prant-com module-wrap" v-if="$route.name === 'CarManageList'">
      <el-form :inline="true" label-width="90px" :model="formData">
        <el-form-item label="车牌号">
          <el-input class="w500" v-model="formData.carNo" placeholder="请输入车牌号" />
        </el-form-item>
        <el-form-item class="center">
          <el-button type="primary" @click="search">查询</el-button>
          <el-button type="primary" plain @click="resetFormData">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="table-area">
        <el-table
          :data="tableData"
          border
          :header-cell-style="$rowClass"
          style="width: 100%"
        >
          <el-table-column prop="joinTime" label="入库时间" />
          <el-table-column prop="carNo"  label="车牌号码" />
          <el-table-column prop="capacity"  label="核载方量(方)" />
          <el-table-column prop="modifyTime"  label="核载更新时间" />
          <!-- <el-table-column prop="capacity" width="100" label="核载更新时间">
            <template slot-scope="scope">
              <span class="theme-color pointer" @click="seeDrivingLicense(scope.row)">
                点击查看
              </span>
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop="sgfNameList" show-overflow-tooltip label="当前关联的施工方" />
          <el-table-column prop="capacity" width="100"  :formatter="formatState" label="状态" />
          <el-table-column label="操作"  align="center" width="80">
            <template slot-scope="scope">
             <span v-if="scope.row.bind"
             class="theme-color pointer" @click="unBindCar(scope.row)">
              解绑
            </span>
            <span v-else>--</span>
            </template>
          </el-table-column> -->
        </el-table>
        <div class="pagination-block">
          <el-pagination
            background
            :current-page.sync="formData.page"
            @current-change="handlerCurPage"
            :total="count"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="查看行驶证"
      :visible.sync="dialogVisible"
      center
      width="50%">
      <div class="img-wrap">
        <el-image :src="drivingLicenseUrl" />
      </div>
    </el-dialog>
    <div class="child-com">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  formatDate, globalClose,
} from '../../utils/index';
import { $gp_groupCarList, $gp_groupCarUnbind, $gp_groupCarTransporting } from '../../api/index';

export default {
  name: 'CarManageList',
  data() {
    return {
      count: 4,
      tableData: [],
      qtcList: [],
      formData: {
        size: 10,
        page: 1,
      },
      years: [],
      months: [],
      days: [],
      dialogVisible: false,
      drivingLicenseUrl: '',
    };
  },
  computed: {
    ...mapGetters(['updateTime', 'userInfo']),
    isProIndex() {
      return this.userInfo.role === 'PROJECT_MANAGER';
    },
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
    $route() {
      // 监听路由是否变化
      this.groupCarList();
    },

  },
  mounted() {
    this.groupCarList();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handlerCurPage(val) {
      console.log(val);
      this.formData.page = val;
      this.groupCarList();
    },
    search() {
      this.groupCarList();
    },
    refreshData() {
      this.groupCarList();
    },
    resetFormData() {
      this.formData = {
        size: 10,
        page: 1,
      };
    },
    formaTime({ firstPassTime }) {
      return formatDate(firstPassTime, 'yyyy-MM-dd HH:mm:ss');
    },
    formatState({ bind }) {
      return bind ? '正常' : '已解绑';
    },
    seeDrivingLicense({ drivingLicenseUrl }) {
      this.dialogVisible = true;
      this.drivingLicenseUrl = drivingLicenseUrl;
    },
    groupCarList() {
      $gp_groupCarList(this.formData).then((res) => {
        this.tableData = res.list;
        this.count = res.totalCount;
      });
    },

    unBindCar({ uuid, carNo, ownerName }) {
      const msg = `请在解绑前确认该车辆是否归属当前关联的施工方，若只归属当前施工方，可解绑；若当前关联了多个施工方，需多方确认后再进行解绑。您确定要解除 ${carNo} 与 ${ownerName} 的绑定关系吗？`;
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          $gp_groupCarTransporting({ carUuid: uuid }).then((res) => {
            console.log('res', res);
            if (res) {
              this.$message.warning('当前车辆正在运输中，无法解绑');
            } else {
              $gp_groupCarUnbind({ uuid }).then(() => {
                this.$message.success('解绑成功');
                this.groupCarList();
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.w500 {
  width: 500px;
}
.table-area {
  position: relative;
  .count-detail {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 10;
  }
}
</style>
