<!--
 * @Author: 许波
 * @Date: 2021-07-13 20:11:20
 * @LastEditTime: 2023-02-01 19:59:49
-->
<template>
  <div class="module-wrap" v-if="$route.name === 'AddFreeCar'">
     <el-form label-width="100px" :rules="rules" :model="formData">
       <div class="flex">
          <div class="flex-item">
            <el-form-item label="弃土场" prop="qtcUuid" required>
              <QtcSelect :width="250" v-model="formData.qtcUuid"/>
            </el-form-item>
            <el-form-item label="车牌类型" prop="cardType" required>
              <CardTypeSelect :width="250"
              :qtcUuid="formData.qtcUuid" v-model="formData.cardType" />
            </el-form-item>
            <el-form-item label="车牌号" prop="license" required>
              <el-input v-model="formData.license" class="w250"></el-input>
            </el-form-item>
            <el-form-item label="过期日期">
              <el-date-picker
                v-model="formData.validTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期" />
            </el-form-item>
          </div>
          <div class="flex-item">
            <el-form-item label="车辆类型" prop="carType" required>
              <CarTypeSelect :width="250"
              :qtcUuid="formData.qtcUuid"
              v-model="formData.carType" />
            </el-form-item>
            <el-form-item label="车主姓名" prop="carusername" required>
              <el-input v-model="formData.carusername" class="w250"></el-input>
            </el-form-item>
            <el-form-item label="开始日期">
              <el-date-picker
                class="w250"
                v-model="formData.startTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期" />
            </el-form-item>
          </div>
       </div>
        <div class="flex fcc">
          <el-form-item class="mt20">
            <el-button type="primary" @click="createFreeCar">确定</el-button>
            <el-button @click="$router.back()">取消</el-button>
          </el-form-item>
        </div>
      </el-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import QtcSelect from '../../components/QtcSelect.vue';
import CardTypeSelect from '../../components/CardTypeSelect.vue';
import CarTypeSelect from '../../components/CarTypeSelect.vue';

import {
  $gp_groupAddWhiteCar,
  // $gp_groupEditWhiteCar,
} from '../../api/index';
import { globalClose } from '../../utils/utils';

export default {
  name: 'AddFreeCar',
  components: {
    QtcSelect,
    CardTypeSelect,
    CarTypeSelect,
  },
  data() {
    return {
      formData: {},
      rules: {
        qtcUuid: [{ required: true, message: '请选择弃土场', trigger: 'blur' }],
        cardType: [{ required: true, message: '请选择车牌类型', trigger: 'blur' }],
        carType: [{ required: true, message: '请选择车辆类型', trigger: 'blur' }],
        carusername: [{ required: true, message: '请输入车主姓名', trigger: 'blur' }],
        license: [{ required: true, message: '请输入车牌号', trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapGetters(['updateTime']),
  },
  watch: {
    updateTime(val) {
      if (val.routeName === this.$options.name) {
        if (val.tag === 'refresh') { this.refreshData(); }
        if (val.tag === 'close') { globalClose(this); }
      }
    },
  },
  created() {
    console.log(this.$route.params);
    const { uuid } = this.$route.params;
    if (uuid) {
      this.getProInfo(uuid);
    }
  },

  methods: {
    validate() {
      const fields = Object.keys(this.rules);
      for (let i = 0; i < fields.length; i += 1) {
        if (this.formData[fields[i]] === '' || this.formData[fields[i]] === null || this.formData[fields[i]] === undefined) {
          this.$message.error(this.rules[fields[i]][0].message);
          return false;
        }
      }
      return true;
    },
    createFreeCar() {
      const checkResult = this.validate();
      if (!checkResult) return;
      $gp_groupAddWhiteCar(this.formData).then(() => {
        this.$confirm('添加成功，注意：非即时生效，10分钟内生效！', '提示', {
          confirmButtonText: '知道啦~',
          showCancelButton: false,
          type: 'warning',
        }).then(() => {
          this.$router.back();
        });
      });
      // if (this.formData.uuid) {
      //   $gp_groupEditWhiteCar(this.formData).then(() => {
      //     this.$parent.gateRecordManual();
      //     this.$router.back();
      //   });
      // } else {

      // }
    },
    // getProInfo(uuid) {
    //   $gp_getProInfo({ uuid }).then((res) => {
    //     console.log(res);
    //     this.formData = res;
    //     const { address, lng, lat } = res;
    //     console.log(address, lng, lat);
    //     this.address = address;
    //     this.position = [lng, lat];
    //     this.dialogVisible = true;
    //     this.$nextTick(() => {
    //       this.$refs.gmap.initMapToMarker(this.position, address);
    //     });
    //   });
    // },

  },
};
</script>

<style lang="scss" scoped>
  .t-gd-custom-map {
    height: 300px !important;
    width: 100%;
    .amap-marker-label {
      color: #ff4739 !important;
      border: none !important;
      background-color: transparent !important;
      font-size: 0.8em !important;
    }
    .t-amap-info-window {
      background-color: white;

      p {
        margin: 0;
        color: #adadad;
        span:last-child {
          font-size: 0.8em;
        }
      }
    }
  }
</style>
